<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('esl_course')" :isNewButton="checkPermission('eslcourse_store')" :isColumns="true"
					@new-button-click="createFormShow()"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('esl_course')" :isNewButton="checkPermission('eslcourse_store')" :isColumns="true"
					@new-button-click="createFormShow()"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
				<b-row>
					<!-- LEVEL ID -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('level_id')">
							<b-form-input type="number" v-model="datatable.queryParams.filter.level_id"></b-form-input>
						</b-form-group>
					</b-col>

					<!-- NAME -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('name')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
						</b-form-group>
					</b-col>

					<!-- DAYS IN A WEEK -->
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('days_in_a_week')">
                            <ParameterSelectbox
                                v-model="datatable.queryParams.filter.days_in_a_week"
                                :multiple="true"
                                code="days_in_a_week"
                            />
                        </b-form-group>
                    </b-col>

					<!-- DAYS OF THE WEEK -->
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('days_of_the_week')">
                        <ParameterSelectbox
                            v-model="datatable.queryParams.filter.days_of_the_week"
                            :multiple="true"
                            code="days_of_the_week"
                        />
                        </b-form-group>
                    </b-col>

					<!--  FACULTY CODE -->
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('hours_in_a_day')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.hours_in_a_day" ></b-form-input>
                        </b-form-group>
                    </b-col>

					<!-- DEPARTMENT CODE -->
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('part_of_the_day')">
                            <ParameterSelectbox
                                v-model="datatable.queryParams.filter.part_of_the_day"
                                code="part_of_the_day"
                            />                        </b-form-group>
                    </b-col>
				</b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
				:total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
				@on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
			</datatable>
			<CommonModal ref="createFormModal" size="l" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('new').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<create-form @createFormSuccess="createFormSuccess" v-if="formProcess == 'create'" />
				</template>
			</CommonModal>
			<CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('edit').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<update-form :formId="formId" @updateFormSuccess="updateFormSuccess" v-if="formProcess == 'update'" />
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";

// Pages
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm";

// Services
import EslCourseService from "@/services/EslCourseService";

// Others
import qs from 'qs'
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox.vue";
import MultiSelectboxArray from "../../../components/interactive-fields/MultiSelectboxArray.vue";

export default {
	components: {
        MultiSelectboxArray,
        MultiSelectbox,
		AppLayout,
		Header,
		HeaderMobile,
		DatatableFilter,
		Datatable,
		CommonModal,
		CreateForm,
		UpdateForm,
	},
	metaInfo() {
		return {
			title: this.$t('esl_course')
		}
	},
	data() {
		return {
			datatable: {
				isLoading: false,
				columns: [
					{
						label: '',
						field: 'buttons',
						html: true,
						sortable: false,
						tdClass: 'p-0 text-center w-40 align-middle',
						buttons: [
							{
								text: this.$t('edit'),
								class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
								permission: "eslcourse_update",
								callback: (row) => {
									this.updateFormShow(row.id)
								}
							},
							{
								text: this.$t('delete'),
								class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
								permission: "eslcourse_delete",
								callback: (row) => {
									this.formDelete(row.id)
								}
							}
						]
					},
					{
						label: this.toUpperCase('id'),
						field: 'id',
						hidden: false,
					},
					{
						label: this.toUpperCase('level_id'),
						field: 'level_id',
						hidden: false,
					},
					{
						label: this.toUpperCase('name'),
						field: 'name',
						hidden: false,
					},
					{
						label: this.toUpperCase('days_in_a_week'),
						field: 'days_in_a_week',

					},
					{
						label: this.toUpperCase('days_of_the_week'),
						field: 'days_of_the_week',
						hidden: false,
						sortable: false,
                        formatFn: (row) => {
                            return Array.isArray(row) ? row.join(', ') : row;
                        }
					},
					{
						label: this.toUpperCase('hours_in_a_day'),
						field: ('hours_in_a_day'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('part_of_the_day'),
						field: ('part_of_the_day'),
						hidden: false,
						sortable: false
					},

				],
				rows: [],
				total: 0,
				filterStatus: true,
				showTable: false,
				queryParams: {
					filter: {},
					sort: 'id',
					page: 1,
					limit: 20
				}
			},
			formProcess: null,
			formId: null,
			form: {},
		}
	},
	created() {
		this.filterSet();
		this.formClear();
	},
	methods: {
		filterSet() {
			this.datatable.queryParams.filter = {
                level_id: null,
                name: null,
                days_in_a_week: null,
                days_of_the_week: null,
                hours_in_a_day: null,
                part_of_the_day: null,
			}
		},
		filterClear() {
			this.filterSet()
			this.getRows();
		},
		filter() {
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		onPageChange(params) {
			this.datatable.queryParams.page = params.currentPage;
			this.getRows();
		},
		onPerPageChange(params) {
			this.datatable.queryParams.limit = params.currentPerPage;
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		changeColumn(key) {
			this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
		},
		onSortChange(params) {
			const sortType = params[0].type == 'desc' ? '-' : '';
			this.datatable.queryParams.sort = sortType + params[0].field
			this.getRows();
		},
		getRows() {
			this.datatable.showTable = true;
			this.datatable.isLoading = true;

			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			return EslCourseService.getAll(config)
				.then((response) => {
					this.datatable.rows = response.data.data
					this.datatable.total = response.data.pagination.total

					this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
				})
				.finally(() => {
					this.datatable.isLoading = false;
				});
		},

		// Clear
		formClear() {
			this.formId = null
			this.formProcess = null
		},

		// Create
		createFormShow() {
			this.formId = null
			this.formProcess = 'create'
			this.$refs.createFormModal.$refs.commonModal.show()
		},
		createFormSuccess() {
			this.$refs.createFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		// Update
		updateFormShow(id) {
			this.formId = id
			this.formProcess = 'update'
			this.$refs.updateFormModal.$refs.commonModal.show()
		},
		updateFormSuccess() {
			this.$refs.updateFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		formDelete(id) {
			this.$swal
				.fire({
					text: this.$t('are_you_sure_to_delete'),
					showCancelButton: true,
					confirmButtonText: this.$t('yes'),
					cancelButtonText: this.$t('no'),
				})
				.then((result) => {
					if (result.isConfirmed) {
						EslCourseService.del(id)
							.then(response => {
								this.$toast.success(this.$t('api.' + response.data.message));
								this.getRows();
								this.formClear();
							})
							.catch(error => {
								this.$toast.error(this.$t('api.' + error.data.message));
							});
					}
				})
		},

	}
}
</script>

